<template>
  <div>
    <kendo-datasource
            :ref="'suggestedCitationSelectorDataSource'"
            :data="suggestedCitationDataSource.data">
    </kendo-datasource>
    <kendo-datasource
            :ref="'otherCitationSelectorDataSource'"
            :data="otherCitationDataSource.data">
    </kendo-datasource>
    <div class="form-entry-container">
        <div class="form-entry-label">Source file name:</div>
        <div class="form-entry-input" style="width: 100%; display:flex; justify-content: flex-start;">
          <div><u>M-ART_2017_GENERAL IN.pdf</u></div>
        </div>
    </div>
    <div class="form-entry-container">
        <div class="form-entry-label">Issue:</div>
        <div class="form-entry-input" style="width: 100%; display:flex; justify-content: flex-start;">
          <div>NONE</div>
        </div>
    </div>
    <div class="form-entry-container">
      <div class="form-entry-label">Select matched citation:</div>
        <div class="form-entry-input">
          <div>
            <b>Suggested:</b>
            <div style="width: 90%;">
              <kendo-grid
                :ref="'suggestedcitationSelectorGrid'"
                :data-source-ref="'suggestedCitationSelectorDataSource'"
                :sortable="sortable"
                :pageable="false"
                :selectable="selectable"
                :scrollable="'true'"
                :filterable="filterable"
                :change="change"
                :columns="suggestedCitationSelectorGridColumns">
              </kendo-grid>
            </div>
            <b>Other:</b>
            <div style="width: 90%;">
              <kendo-grid
                :ref="'otherCitationSelectorGrid'"
                :data-source-ref="'otherCitationSelectorDataSource'"
                :sortable="sortable"
                :pageable="false"
                :pageSize=2
                :selectable="selectable"
                :scrollable="'true'"
                :filterable="filterable"
                :change="change"
                :columns="otherCitationSelectorGridColumns">
              </kendo-grid>
            </div>
          </div>
        </div>
    </div>
    <div class="form-entry-container">
        <div class="form-entry-label">Match as:</div>
        <div class="form-entry-input">
          <kendo-datasource
            :ref="'matchTypeSelectorDataSource'"
            :data="matchTypeSelector">
          </kendo-datasource>
          <kendo-dropdownlist
          :id="'matchTypeSelectorSelector'"
          :ref="'documentTypeSelectorReference'"
          :data-source-ref="'matchTypeSelectorDataSource'"
          :data-text-field="'SelectorName'"
          :data-value-field="'SelectorIdentity'"
          :change="selectImportType"
          style="width: auto;">
          </kendo-dropdownlist>
        </div>
    </div>
    <div class="form-entry-container">
        <div class="form-entry-label">Imported file name:</div>
        <div class="form-entry-input">&nbsp;</div>
    </div>
    <div style="justify-content: flex-end; display: flex; flex-direction: row;">
      <button style="width: 250px; height: 25px;" @click="matchFile" :class="this.buttonEnableClass">Match Selected citation</button>
      <button style="width: 150px; height: 25px;" @click="cancelMatch">Cancel</button>
    </div>
  </div>
</template>

<script>
import SuggestedCitationDataSource from '@/assets/data/SuggestedCitation.json'
import OtherCitationDataSource from '@/assets/data/OtherCitation.json'

export default {
  name: 'citation-match',
  buttonEnableClass: 'buttonEnabled',
  methods: {
    cancelMatch: function () { },
    selectImportType: function () { },
    matchFile: function () {
      alert('This citation file will be renamed file M_AAA_2017_CITATION01(1234).pdf in order to respect file naming conventions')
    },
    suggestCitation: function () {
      alert('No citation was found')
    },
    change: function () {
      if (this.buttonEnableClass === 'buttonDisabled') {
        this.buttonEnableClass = 'buttonEnabled'
      } else {
        this.buttonEnableClass = 'buttonDisabled'
      }
    }
  },
  data () {
    return {
      selectable: true,
      scrollable: true,
      sortable: true,
      filterable: true,
      pageableSuggested: false,
      pageableOther: false,
      suggestedCitationDataSource: SuggestedCitationDataSource,
      otherCitationDataSource: OtherCitationDataSource,
      buttonEnableClass: 'buttonDisabled',
      matchTypeSelector: [
        { SelectorIdentity: 1, SelectorName: 'Select an option' },
        { SelectorIdentity: 1, SelectorName: 'Main' },
        { SelectorIdentity: 2, SelectorName: 'Supplementary' }
      ],
      suggestedCitationSelectorGridColumns: [
        { field: 'PublicationIdentity', width: '10px', title: 'Citation ID' },
        { field: 'Author', title: 'Author', width: '20px' },
        { field: 'Year', title: 'Year', width: '10px' },
        { field: 'Title', title: 'Title', width: '20px', template: '<div id="truncate">#:Title#</div>' },
        { field: 'Abstract', title: 'Abstract', width: '20px', template: '<div id="truncate">#:Abstract#</div>' },
        { field: 'DOI', title: 'DOI', width: '20px', template: '<div id="truncate">#:DOI#</div>' },
        { field: 'Stage', title: 'Stage', width: '15px' },
        { field: 'Probability', title: 'Probability', width: '10px' }
      ],
      otherCitationSelectorGridColumns: [
        { field: 'PublicationIdentity', width: '10px', title: 'Citation ID' },
        { field: 'Author', title: 'Author', width: '20px' },
        { field: 'Year', title: 'Year', width: '10px' },
        { field: 'Title', title: 'Title', width: '20px', template: '<div id="truncate">#:Title#</div>' },
        { field: 'Abstract', title: 'Abstract', width: '20px', template: '<div id="truncate">#:Abstract#</div>' },
        { field: 'DOI', title: 'DOI', width: '20px', template: '<div id="truncate">#:DOI#</div>' },
        { field: 'Stage', title: 'Stage', width: '15px' },
        { field: 'Probability', title: 'Probability', width: '10px', template: '<div>NA</div>' }
      ]
    }
  }
}
</script>

<style scoped>
  .buttonDisabled {
    background: grey;
  }
  .buttonEnabled {
    background: #0069D8;
  }
</style>
