<template>
    <div class="import-file">
      <div class="form-entry-container">
        <div class="form-entry-label">Document Type</div>
        <div class="form-entry-input">
          <kendo-datasource
            :ref="'documentTypeSelectorDataSource'"
            :data="documentTypeSelector">
          </kendo-datasource>
          <kendo-dropdownlist
          :id="'documentTypeSelector'"
          :ref="'documentTypeSelectorReference'"
          :data-source-ref="'documentTypeSelectorDataSource'"
          :data-text-field="'SelectorName'"
          :data-value-field="'SelectorIdentity'"
          :change="selectImportType"
          style="width: auto;">
          </kendo-dropdownlist>
        </div>
      </div>
      <div v-show="this.viewVersion === 10">
        <FormEntryContainer
          title="Format of File"
          type="dropdown"
          :datasource="this.documentFormatDataSource"
          :valuefield="'DocumentFormatIdentity'"
          :textfield="'DocumentFormatName'">
        </FormEntryContainer>
        <FormEntryContainer title="Select File" type="file"></FormEntryContainer>
        <FormEntryContainer
          title="Import to Stage"
          type="dropdown"
          :datasource="this.stageDataSource"
          :valuefield="'StageIdentity'"
          :textfield="'StageName'">
        </FormEntryContainer>
        <FormEntryContainer title="Added Materials" type="checkbox"></FormEntryContainer>
        <button style="width: 100px; height: 25px;" @click="gotoView11()">Retrieve File</button>
      </div>
      <div v-if="this.viewVersion === 11">
        <kendo-datasource
            :ref="'citationDataSource1'"
            :data="citationDataSource.data"
            :group-field="'Group'"
            :page-size="'8'"
            :filter="filter11">
          </kendo-datasource>
          <kendo-grid
            :ref="'importGrid11'"
            :data-source-ref="'citationDataSource1'"
            style="width: calc(100vw - 20px);"
            :pageable="pageable"
            :sortable="sortable"
            :selectable="selectable"
            :scrollable="scrollable"
            :columns="columns11">
          </kendo-grid>
      </div>
      <div v-if="this.viewVersion === 20">
        <FormEntryContainer title="Select File(s)" type="folder"></FormEntryContainer>
        <button style="width: 100px; height: 25px;" @click="gotoView21()">Retrieve File(s)</button>
      </div>
      <div v-if="this.viewVersion === 21">
        File(s) from the table below with no issues can be imported
        <!--The following file(s) from the table below cannot be imported.  If you press the continue button, the system will allow you to select the remaining files.-->
       <kendo-datasource
            :ref="'citationDataSource21'"
            :data="citationDataSource.data"
            :page-size="'8'"
            :filter="filter21">
        </kendo-datasource>
        <kendo-grid
            :ref="'importGrid21'"
            :data-source-ref="'citationDataSource21'"
            style="width: calc(100vw - 20px);"
            :pageable="pageable"
            :sortable="sortable"
            :selectable="selectable"
            :scrollable="scrollable"
            :columns="columns21">
        </kendo-grid>
        <div>
          <button style="width: 150px; height: 25px;" @click="gotoView22()">Continue</button>
          <button style="width: 100px; height: 25px;" @click="gotoStart()">Cancel</button>
        </div>
      </div>
      <div v-if="this.viewVersion === 22">
        file(s) from the table below with no issues can be imported
        <kendo-datasource
            :ref="'citationDataSource22'"
            :data="citationDataSource.data"
            :page-size="'8'"
            :filter="filter22">
        </kendo-datasource>
        <kendo-grid
            :ref="'importGrid22'"
            :data-source-ref="'citationDataSource22'"
            style="width: calc(100vw - 20px);"
            :editable="editable"
            :pageable="pageable"
            :sortable="sortable"
            :selectable="selectable"
            :scrollable="scrollable"
            :columns="columns22">
        </kendo-grid>
        <div style="display: flex; flex-direction: row; justify-content:space-between;">
          <div>
            <button style="width: 150px; height: 25px;" @click="gotoView23()">Suggest matches</button>
          </div>
          <div>
            <button style="width: 150px; height: 25px;">Select All/Clear All</button>
            <button style="width: 100px; height: 25px;">Import</button>
            <button style="width: 100px; height: 25px;" @click="gotoStart()">Cancel</button>
          </div>
        </div>
      </div>
      <div v-if="this.viewVersion === 23">
        <kendo-datasource
            :ref="'citationDataSource23'"
            :data="citationDataSource.data"
            :page-size="'8'"
            :filter="filter22">
        </kendo-datasource>
        <kendo-grid
            :ref="'importGrid23'"
            :data-source-ref="'citationDataSource23'"
            style="width: calc(100vw - 20px);"
            :pageable="pageable"
            :sortable="sortable"
            :selectable="selectable"
            :scrollable="scrollable"
            :columns="columns23">
        </kendo-grid>
        <div style="display: flex; flex-direction: row; justify-content:space-between;">
          <div>
            <button style="width: 150px; height: 25px;" @click="gotoView23()">Suggest matches</button>
          </div>
          <div>
            <button style="width: 150px; height: 25px;">Select All/Clear All</button>
            <button style="width: 100px; height: 25px;">Import</button>
            <button style="width: 100px; height: 25px;" @click="gotoStart()">Cancel</button>
          </div>
        </div>
      </div>
      <div v-if="this.viewVersion === 3">
        <div>
          <kendo-datasource
            :ref="'citationDataSource1'"
            :data="citationDataSource.data"
            :group-field="'Group'"
            :page-size="'8'"
            :filter="filter21">
          </kendo-datasource>
          <kendo-grid
            :ref="'importGrid3'"
            :data-source-ref="'citationDataSource21'"
            style="width: calc(100vw - 20px);"
            :pageable="pageable"
            :sortable="sortable"
            :selectable="selectable"
            :scrollable="scrollable"
            :columns="columns1">
          </kendo-grid>
        </div>
        <div>
          <kendo-datasource
            :ref="'citationDataSource22'"
            :data="citationDataSource.data"
            :group-field="'Group'"
            :page-size="'8'"
            :filter="filter22">
          </kendo-datasource>
          <kendo-grid
            :ref="'importGrid1'"
            :data-source-ref="'citationDataSource22'"
            style="width: calc(100vw - 20px);"
            :editable="editable"
            :pageable="pageable"
            :sortable="sortable"
            :selectable="selectable"
            :scrollable="scrollable"
            :columns="columns2">
          </kendo-grid>
        </div>
        <div style="display: flex; flex-direction: column;">
          <button style="width: 150px; height: 25px;" v-show="this.viewVersion === 22">Fix Issues</button>
          <button style="width: 150px; height: 25px;" v-show="this.viewVersion === 22">Select All/Clear All</button>
          <button style="width: 100px; height: 25px;" v-show="this.importType === 'Citation'">Import All</button>
          <button style="width: 100px; height: 25px;" v-show="this.viewVersion === 22">Import Selected</button>
          <button style="width: 100px; height: 25px;">Cancel Import</button><br>
        </div>
        <div v-show="this.importType === 'Citation'">
          - All Imported duplicate {{ this.importType }}s will be automatically excluded with reason "Duplicate Publications"<br>
          - All {{ this.importType }}s with formatting issues will not be imported even if selected<br>
          - All {{ this.importType }}s already found in the Master Database will not be created but they will be linked to the project.
        </div>
      </div>
      <div>&nbsp;</div>
      <kendo-window :ref="'detachWindow'"
                      :width="windowWidth"
                      :title="windowTitle"
                      :visible="false"
                      :modal="true"
                      :top="'0px'">
            <component class="component" :is="component"></component>
      </kendo-window>
      <kendo-window :ref="'matchCitation'"
                      :width="matchWindowWidth"
                      :title="windowTitle"
                      :visible="false"
                      :scrollable="false"
                      :modal="true"
                      :top="'0px'">
            <component class="component" :is="component"></component>
      </kendo-window>
    </div>
</template>

<script>
import FormEntryContainer from '@/containers/FormEntryContainer'
import CitationDataSource from '@/assets/data/Citation.json'
import StageDataSource from '@/assets/data/Stage.json'
import DocumentFormatDataSource from '@/assets/data/DocumentFormat.json'
import MatchCitation from '@/views/secure/manage/templates/MatchCitation'
import $ from 'jquery'

export default {
  name: 'import-file',
  windowVisible: false,
  retrieveFolder: true,
  components: {
    FormEntryContainer,
    MatchCitation
  },
  data () {
    return {
      viewVersion: 10,
      component: '',
      windowWidth: '',
      matchWindowWidth: 900,
      windowTitle: '',
      citationDataSource: CitationDataSource,
      stageDataSource: StageDataSource,
      documentFormatDataSource: DocumentFormatDataSource,
      filter21: { field: 'ImportType', operator: 'eq', value: 'NonPdfCitation' },
      filter22: { field: 'ImportType', operator: 'eq', value: 'ImportCitation' },
      pageable: true,
      sortable: true,
      scrollable: true,
      selectable: 'multiple, row',
      groupable: true,
      variableTypeDataSource: [
        { VariableTypeName: 'character' },
        { VariableTypeName: 'numeric' },
        { VariableTypeName: 'boolean' },
        { VariableTypeName: 'date' }
      ],
      documentTypeSelector: [
        { SelectorIdentity: 1, SelectorName: 'Import Citation' },
        { SelectorIdentity: 2, SelectorName: 'Import Full Text Document' }
      ],
      matchAsSelector: [
        { SelectorIdentity: 1, SelectorName: 'Main' },
        { SelectorIdentity: 2, SelectorName: 'Supplementary' }
      ],
      citationSelector: [
        { SelectorIdentity: 1, SelectorName: 'None' },
        { SelectorIdentity: 2, SelectorName: 'ID: 1234 Ray-2019-Title' }
      ],
      citationSortSelector: [
        { SelectorIdentity: 1, SelectorName: 'None' },
        { SelectorIdentity: 1, SelectorName: 'Matched' },
        { SelectorIdentity: 2, SelectorName: 'Unmatched' }
      ],
      columns11: [
        { field: 'FullText', title: 'Source file name', width: '220px' },
        { field: 'FormatError', title: 'Issue', width: '220px' },
        {
          title: 'Citation Fields',
          headerAttributes: { class: 'grid-header-de1' },
          columns1: [
            { field: 'PublicationIdentity', title: 'Citation ID', width: '120px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Author', title: 'Author', width: '120px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Year', title: 'Year', width: '80px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Title', title: 'Title', template: '<div id="truncate">#:Title#</div>', width: '300px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Abstract', title: 'Abstract', template: '<div id="truncate">#:Abstract#</div>', width: '400px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'TypeOfPublication', title: 'Type of Publication', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'JournalName', title: 'Journal Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Volume', title: 'Volume', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Issue', title: 'Issue', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Pages', title: 'Page(s)', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ConferenceName', title: 'Conference Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ConferenceDate', title: 'Date(s) of Conference', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ReportName', title: 'Report Name', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'OrganizingBody', title: 'Organizing Body', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'ReportDate', title: 'Date of Report', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DatabaseName', title: 'Name of Database', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'AccessionNumber', title: 'Accession Number', width: '200px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DOI', title: 'DOI', width: '200px', template: '<div id="truncate">#:DOI#</div>', headerAttributes: { class: 'grid-header-de1' } }
          ]
        },
        {
          title: 'Other Citation Fields',
          headerAttributes: { class: 'grid-header-de2' },
          columns: [
            { field: 'Duplicate', title: 'Duplicate', width: '200px', headerAttributes: { class: 'grid-header-de2' } },
            { field: 'Stage', title: 'Stage', width: '200px', headerAttributes: { class: 'grid-header-de2' } },
            { field: 'AddedMaterials', title: 'Added Materials', width: '200px', headerAttributes: { class: 'grid-header-de2' } }
          ]
        }
      ],
      columns21: [
        { field: 'FullText', title: 'Source file name', width: '220px' },
        { field: 'FormatError', title: 'Issue', width: '220px' }
      ],
      columns22: [
        { field: 'FullText', title: 'Source file name', width: '220px' },
        { field: 'FormatError', title: 'Issue', width: '90px' },
        {
          title: 'Match<br>Citation',
          command: [{
            name: 'Match',
            className: 'match_button',
            click: this.matchCitation
          }],
          width: '80px',
          headerAttributes: { class: 'grid-header-dark' }
        },
        {
          title: 'Match source file to this citation',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            {
              field: 'PublicationIdentity',
              title: 'Citation ID',
              // editor: this.citationIdDropDownEditorFunction,
              // template: this.citationIdDropDownTemplateFunction,
              width: '110px',
              headerAttributes: { class: 'grid-header-de1' }
            },
            { field: 'Author', title: 'Author', width: '90px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Year', title: 'Year', width: '70px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Title', title: 'Title', template: '<div id="truncate">#:Title#</div>', width: '70px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Abstract', title: 'Abstract', template: '<div id="truncate">#:Abstract#</div>', width: '70px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DOI', title: 'DOI', template: '<div id="truncate">#:DOI#</div>', width: '70px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Stage', title: 'Stage', width: '60px', headerAttributes: { class: 'grid-header-de1' } }
          ]
        },
        { field: 'MatchAs', title: 'Match as', width: '120px', template: this.matchAsTemplateFunction },
        { field: 'ImportedName', title: 'Imported file name', width: '220px' }
      ],
      columns23: [
        { field: 'FullText', title: 'Source file name', width: '220px' },
        { field: 'FormatError', title: 'Issue', width: '220px' },
        {
          title: 'Match source files to this citation',
          headerAttributes: { class: 'grid-header-de1' },
          columns: [
            { field: 'PublicationIdentity', title: 'Citation ID', width: '80px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Author', title: 'Author', width: '120px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Year', title: 'Year', width: '80px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Title', title: 'Title', template: '<div id="truncate">#:Title#</div>', width: '300px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'DOI', title: 'DOI', template: '<div id="truncate">#:DOI#</div>', width: '70px', headerAttributes: { class: 'grid-header-de1' } },
            { field: 'Stage', title: 'Stage', width: '200px', template: '<div id="truncate">#:URL#</div>', headerAttributes: { class: 'grid-header-de1' } }
          ]
        },
        { field: 'ImportedName', title: 'Imported file name', width: '220px' }
      ]
    }
  },
  methods: {
    selectImportType: function (e) {
      var documentTypedataItem = this.$refs.documentTypeSelectorDataSource.data
      var selectorName = documentTypedataItem[e.sender.selectedIndex].SelectorName
      if (selectorName === 'Import Citation') {
        this.viewVersion = 10
        this.filter = { field: 'ImportType', operator: 'eq', value: 'Citation' }
      }
      if (selectorName === 'Import Full Text Document') {
        this.viewVersion = 20
        this.filter = { field: 'ImportType', operator: 'eq', value: 'Full Text' }
      }
    },
    importCitation: function () {
      this.isRetrieved = false
      this.viewVersion = 10
      this.filter = { field: 'ImportType', operator: 'eq', value: 'Citation' }
    },
    importFullText: function () {
      this.isRetrieved = false
      this.viewVersion = 21
      this.filter = { field: 'ImportType', operator: 'eq', value: 'Full Text' }
    },
    gotoStart: function () {
      var documentTypeSelectorReference = this.$refs.documentTypeSelectorReference.kendoWidget()
      documentTypeSelectorReference.value('1')
      this.viewVersion = 10
    },
    gotoView11: function () {
      this.viewVersion = 11
    },
    gotoView21: function () {
      this.viewVersion = 21
    },
    gotoView22: function () {
      this.viewVersion = 22
      this.retrieveFolder = false
    },
    gotoView23: function () {
      this.viewVersion = 23
    },
    cancelWindow: function () {
      var windowWidget = this.$refs.detachWindow.kendoWidget()
      windowWidget.close()
    },
    matchCitation: function () {
      var matchWindowWidget = this.$refs.matchCitation.kendoWidget()
      this.windowTitle = 'match citation to file'
      this.windowWidth = '1100px'
      this.component = 'MatchCitation'
      matchWindowWidget.close()
      matchWindowWidget.open().center()
    },
    citationIdDropDownTemplateFunction: function (container, options) {
      // $('<input/>').appendTo(container).kendoDropDownList({
      //   template: '<div style="display: flex; flex-direction: row;">' +
      //     '<div style="width: 50px;">#: PublicationIdentity #</div>' +
      //     '<div style="width: 50px;" id="truncate">#: Author #</div>' +
      //     '<div style="width: 30px;">#: Year #</div>' +
      //     '<div style="width: 80px;" id="truncate">#: Title #</div>' +
      //     '</div>',
      //   autoWidth: true,
      //   dataSource: this.citationDataSource.data
      // })
      var selectInput = '<select class=".k-widget .k-dropdown">' +
        '<option>8649</option></select>' +
        '<span class=".k-widget .k-dropdown">' +
          '<span class=".k-input"></span>' +
          '<span class=".k-select"></span>' +
        '</span>'
      return selectInput
    },
    citationIdDropDownEditorFunction: function (container, options) {
      console.log('editor')
      console.log(options.field)
      $('<input name="' + options.field + '"/>').appendTo(container).kendoDropDownList({
        headerTemplate: '<div style="display: flex; flex-direction: row;">' +
          '<div style="width: 50px;">CitationId</div>' +
          '<div style="width: 40px;">Author</div>' +
          '<div style="width: 30px;">Year</div>' +
          '<div  style="width: 80px;">Title</div>' +
          '</div>',
        template: '<div style="display: flex; flex-direction: row;">' +
          '<div style="width: 50px;">#: PublicationIdentity #</div>' +
          '<div style="width: 40px;" id="truncate">#: Author #</div>' +
          '<div style="width: 30px;">#: Year #</div>' +
          '<div style="width: 80px;" id="truncate">#: Title #</div>' +
          '</div>',
        autoWidth: true,
        dataTextField: 'PublicationIdentity',
        dataValueField: 'PublicationIdentity',
        dataSource: this.citationDataSource.data
      })
    },
    matchAsTemplateFunction: function (container, options) {
      console.log('oo')
      console.log(options)
      // $('<input name="' + options.field + '"/>').appendTo(container).kendoDropDownList({
      //   optionLabel: 'Select an Option',
      //   autoWidth: true,
      //   dataTextField: 'SelectorName',
      //   dataValueField: 'SelectorIdentity',
      //   dataSource: this.matchAsSelector
      // })
      return '<select><option>Select an Option...</option><option>Main</option><option>Supplementary</option></select>'
    },
    matchAsDropDownEditorFunction: function (container, options) {
      $('<input name="' + options.field + '"/>').appendTo(container).kendoDropDownList({
        autoWidth: true,
        dataTextField: 'SelectorName',
        dataValueField: 'SelectorIdentity',
        dataSource: this.matchAsSelector
      })
    }
  }
}
</script>

<style>

  .match_button {
    background-color: #0069D8;
    border-radius: 5px !important;
    border-width: 0px !important;
    color: #fff !important;
    font-size: 0.8em !important;
    margin: 0.2em !important;
    padding: 0.3em !important;
    cursor: pointer !important;
    outline: 0 !important
  }

  #truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .import-file {
    display: grid;
    grid-gap: 10px;
  }

  .k-reset p {
    font-size: 1em !important;
  }

  .test {
    background: red;
  }
</style>
